export default [
    {
        path: "",
        redirect: { name: "StudentConfirm" },
        meta: {
            roles: [],
        },
    },
    {
        path: "confirm",
        component: () => import("@/views/student/Confirm"),
        name: "StudentConfirm",
        meta: {
            roles: [],
        },
    },
    {
        path: "info",
        component: () => import("@/views/student/Info"),
        name: "StudentInfo",
        meta: {
            roles: [],
        },
    },
    {
        path: "exam",
        component: () => import("@/views/student/Exam"),
        name: "StudentExam",
        meta: {
            roles: [],
        },
    },
];
