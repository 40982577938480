import { currentExamination } from "@/api/student-test";
import Store from "@/store";
import { isMobile } from "@/utils/devices";

export async function checkExamination(to, from, next) {
    try {
        if (isMobile()) {
            Store.commit(
                "app/SET_ERROR_MESSAGE",
                "Hiện hệ thống không cho phép làm bài trên điện thoại hay máy tính bảng. Bạn hãy dùng máy tính."
            );
            return next({ name: "Forbidden" });
        }
        const result = await currentExamination();
        const { examination, form, students, subjectName, info } = result.data;
        Store.commit("student/SET_EXAMINATION", examination);
        Store.commit("student/SET_STUDENTS", students);
        Store.commit("student/SET_SUBJECT_NAME", subjectName);
        const name = "StudentExam";
        if (form) {
            Store.commit("student/SET_FORM_ID", form);
            Store.commit('student/SET_INFO', info)
            if (to.name === name) {
                next();
            } else {
                next({ name });
            }
        } else {
            if (to.name === name) {
                next({ name: "StudentConfirm" });
            } else {
                next();
            }
        }
    } catch (e) {
        Store.commit(
            "app/SET_ERROR_MESSAGE",
            e?.response?.data?.error?.message
        );
        if (e?.response?.data?.error?.code === 423) {
            next({ name: "Login" });
        } else {
            next({ name: "Forbidden" });
        }
    }
}
