import Vue from "vue";
import VueRouter from "vue-router";
import adminRoutes from "./modules/admin";
import pagesRoutes from "./modules/pages";
import studentRoutes from "./modules/student";
import { checkExamination } from "./middleware";
Vue.use(VueRouter);

const routes = [
    {
        name: "LoginWithMsal",
        path: "/login-with-msal/:role",
        component: () => import("@/views/pages/LoginWithMsal"),
    },
    {
        path: "/pages",
        component: () => import("@/layouts/pages/Index"),
        children: pagesRoutes,
    },
    {
        path: "/student",
        component: () => import("@/layouts/SmallLayout"),
        children: studentRoutes,
        beforeEnter: checkExamination,
    },
    {
        path: "/admin",
        component: () => import("@/layouts/dashboard/Index"),
        children: adminRoutes,
        redirect: "/admin/original-exams",
    },
    {
        path: "/logout",
        name: "Logout",
    },
    {
        path: "/",
    },
    {
        path: "/canborade",
        redirect: "/admin/original-exams-by-officer",
    },
    {
        path: "/supervisor",
        redirect: "/admin/supervisors/monitoring",
    },
    {
        path: "/assistant",
        redirect: "/admin/assistants/monitoring",
    },
    {
        path: "/administrator",
    },
    {
        path: "*",
        redirect: "/404",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
