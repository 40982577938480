export default [
  {
    path: "profile",
    name: "Hồ sơ",
    component: () => import("@/views/admin/profile/index"),
    meta: {
      roles: ["Sa"],
    },
  },
  {
    path: "original-exams",
    name: "Đề gốc",
    component: () => import("@/views/admin/original-exams/index"),
    meta: {
      roles: ["Sa", "A"],
    },
  },
  {
    path: "exam-generation",
    name: "Sinh đề",
    component: () => import("@/views/admin/exam-generation/index"),
    meta: {
      roles: ["Sa", "A"],
    },
  },
  {
    path: "exams",
    name: "Đề thi",
    component: () => import("@/views/admin/exams/index"),
    meta: {
      roles: ["Sa", "A"],
    },
  },
  {
    path: "original-exams/:id/questions",
    name: "Câu hỏi",
    component: () => import("@/views/admin/original-exams/show"),
    meta: {
      roles: ["Sa", "A"],
    },
  },
  {
    name: "Kíp thi",
    path: "examinations",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/examinations/index"),
  },
  {
    name: "Học phần",
    path: "subjects",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/subject/index"),
  },
  {
    name: "Sinh viên Kíp thi",
    path: "examinations/:id/students",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/student/index"),
  },
  {
    name: "Điều khiển Phòng thi",
    path: "examinations/:id/room",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/examinations/Room"),
  },
  {
    name: "Kết quả thi",
    path: "examinations/:id/result",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/examinations/Result"),
  },
  {
    name: "Giám sát thi",
    path: "exam-monitoring",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/exam-monitoring/index"),
  },
  {
    name: "Giám thị coi thi",
    path: "supervisors/monitoring",
    component: () => import("@/views/admin/exam-monitoring/Monitoring"),
    meta: {
      roles: ["Su", "Sa", "A"],
    },
  },
  {
    path: "operators",
    name: "Ban điều hành",
    component: () => import("@/views/admin/operators/index"),
    meta: {
      roles: ["Sa"],
    },
  },
  {
    path: "lecturers",
    name: "Giảng viên",
    component: () => import("@/views/admin/lecturer/index"),
    meta: {
      roles: ["Sa", "A"],
    },
  },
  {
    name: "Cán bộ",
    path: "officers",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/officers/index"),
  },
  {
    name: "Cán bộ ra đề",
    path: "officers-by-examinations",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/officers-by-examinations/index"),
  },
  {
    name: "Phòng thi",
    path: "rooms",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/rooms/index"),
  },
  {
    name: "Phân phòng thi",
    path: "rooms/:id",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/rooms/show"),
  },
  {
    name: "Bài thi",
    path: "forms",
    meta: {
      roles: ["Sa", "A"],
    },
    component: () => import("@/views/admin/forms/index"),
  },
  {
    path: "original-exams-by-officer",
    name: "Đề gốc (cán bộ)",
    component: () => import("@/views/admin/original-exams-by-officer/index"),
    meta: {
      roles: ["O"],
    },
  },
  {
    path: "original-exams-by-officer/:id/questions",
    name: "Chi tiết đề gốc (cán bộ)",
    component: () => import("@/views/admin/original-exams-by-officer/show"),
    meta: {
      roles: ["O"],
    },
  },
  {
    path: "deploy",
    name: "Triển khai",
    component: () => import("@/views/admin/deploy/index"),
    meta: {
      roles: ["Sa"],
    },
  },
  {
    path: "assistants",
    name: "Trợ lý",
    component: () => import("@/views/admin/assistant/index"),
    meta: {
      roles: ["Sa", "A"],
    },
  },
  {
    path: "assistants/monitoring",
    name: "Giám sát giám thị",
    component: () => import("@/views/admin/assistant-monitoring/index"),
    meta: {
      roles: ["Sa", "A", "As"],
    },
  },
];
