import { login, logout, getInfo, loginWithMsal } from "@/api/user";
import { getAuth, setAuth } from "@/utils/auth";

const state = {
    auth: getAuth(),
    user: null,
    ip: undefined,
};

const mutations = {
    SET_AUTH: (state, payload) => {
        state.auth = payload;
        setAuth(payload);
    },
    SET_USER: (state, user) => {
        state.user = user;
    },
    SET_IP: (state, ip) => {
        state.ip = ip;
    },
};

const actions = {
    login({ commit }, userInfo) {
        const { email, password } = userInfo;
        return new Promise((resolve, reject) => {
            login({ email: email.trim(), password: password })
                .then((res) => {
                    commit("SET_AUTH", true);
                    resolve(res.home_url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    loginWithMsal({ commit }, payload) {
        return new Promise((resolve, reject) => {
            loginWithMsal(payload)
                .then((res) => {
                    commit("SET_AUTH", true);
                    resolve(res.home_url);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // get user info
    getInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getInfo()
                .then((response) => {
                    const { data } = response;
                    if (!data) {
                        reject("Verification failed, please Login again.");
                    }
                    const { user, ip } = data;
                    commit("SET_USER", user);
                    commit("SET_IP", ip);
                    resolve(user);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // user logout
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            logout()
                .then(() => {
                    commit("SET_AUTH", false);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // remove auth
    removeAuth({ commit }) {
        return new Promise((resolve) => {
            commit("SET_AUTH", false);
            resolve();
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
