import request from "@/utils/request";

function _currentExamination(params = {}) {
    return request({
        url: "/student/examination",
        method: "get",
        params,
    });
}

export async function currentExamination() {
    let params = {};
    const share = sessionStorage.getItem("share");
    let result;
    if (share) {
        params["share"] = share;
        result = await _currentExamination(params);
    } else {
        result = await _currentExamination({});
    }
    return result;
}

export function saveInfo(data) {
    if (sessionStorage.getItem("share")) {
        data["share"] = sessionStorage.getItem("share");
    }
    return request({
        url: "/student/save-info",
        method: "post",
        data,
    });
}

export function getForm() {
    const params = {};
    if (sessionStorage.getItem("share")) {
        params["share"] = sessionStorage.getItem("share");
    }
    return request({
        url: "/student/get-current-info",
        method: "get",
        params,
    });
}

export function confirm() {
    const data = {};
    if (sessionStorage.getItem("share")) {
        data["share"] = sessionStorage.getItem("share");
    }
    return request({
        url: "/student/confirm",
        method: "post",
        data,
    });
}
