export function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    const testUserAgent = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
    // const testMouse = !window.matchMedia("(any-pointer: fine)").matches

    return testUserAgent
}