import request from "@/utils/request";

export function login(data) {
    return request({
        url: "/auth/login",
        method: "post",
        data,
    });
}

export function loginWithMsal(data) {
    return request({
        url: "/auth/login-with-msal",
        method: "post",
        data,
    });
}

export function getInfo() {
    return request({
        url: "/auth/me",
        method: "get",
    });
}

export function logout() {
    return request({
        url: "/auth/logout",
        method: "post",
    });
}

export function changePassword(data) {
    return request({
        url: "/auth/password/change",
        method: "post",
        data,
    });
}

export function index(params) {
    return request({
        url: "/users",
        method: "get",
        params,
    });
}
export function update(id, data) {
    return request({
        url: "/users/" + id,
        method: "put",
        data,
    });
}
export function show(id) {
    return request({
        url: "/users/" + id,
        method: "get",
    });
}
export function store(data) {
    return request({
        url: "/users",
        method: "post",
        data,
    });
}
export function destroy(id) {
    return request({
        url: "/users/" + id,
        method: "delete",
    });
}
