const state = {
  image: undefined,
  examination: undefined,
  students: undefined,
  formId: undefined,
  subjectName: undefined,
  info: undefined,
  snapshotWithCam: undefined,
  lang: localStorage.getItem("lang") || "vi",
};

const mutations = {
  SET_IMAGE(state, image) {
    state.image = image;
  },
  SET_EXAMINATION(state, payload) {
    state.examination = payload;
  },
  SET_STUDENTS(state, payload) {
    state.students = payload;
  },
  SET_FORM_ID(state, payload) {
    state.formId = payload;
  },
  SET_SUBJECT_NAME(state, payload) {
    state.subjectName = payload;
  },
  SET_INFO(state, payload) {
    state.info = payload;
  },
  SET_SNAPSHOT_WITH_CAM(state, payload) {
    state.snapshotWithCam = payload;
  },
  SET_LANG(state, payload) {
    state.lang = payload;
    localStorage.setItem("lang", payload);
    window.location.reload();
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
