const getters = {
  barColor: state => state.app.barColor,
  barImage: state => state.app.barImage,
  drawer: state => state.app.drawer,
  snackbarShow: state => state.app.snackbarShow,
  snackbarText: state => state.app.snackbarText,
  snackbarType: state => state.app.snackbarType,
  name: state => state.user.name,
  email: state => state.user.email,
  name: state => state.user.name,
  loader: state => state.app.loader
};
export default getters;
