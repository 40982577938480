import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("date", function(value) {
  if (!value) return "";

  return dayjs(value).format("DD/MM/YYYY");
});

Vue.filter("time", function(value) {
  if (!value) return "";

  return dayjs(value).format("hh:mm A");
});

Vue.filter("datetime", function(value) {
  if (!value) return "";

  return dayjs(value).format("DD/MM/YYYY hh:mm A");
});

Vue.filter("money", function(value) {
  if (value != null)
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
});
