import router from "./router/index";
import store from "./store/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getAuth } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";

NProgress.configure({ showSpinner: false });

const whiteList = ["Login", "LoginWithMsal"];

const commonRoutes = [
    "/403",
    "/404",
    "/pages/login",
    "/giamthi",
    "/troly",
    "/login-with-msal/student",
    "/login-with-msal/admin",
];

router.beforeEach(async (to, _from, next) => {
    if (to.query.share) {
        sessionStorage.setItem("share", String(to.query.share));
    }
    if (to.path === "/") {
        return next("/student");
    }
    NProgress.start();
    document.title = getPageTitle(to.name);
    const isAuth = getAuth();
    if (to.path === "/administrator")
        window.location.href = "/pages/login?admin=true";

    if (commonRoutes.includes(to.path.toLowerCase())) return next();

    if (isAuth) {
        if (to.path === "/logout") {
            await store.dispatch("user/logout");
            return next({ path: "/pages/login" });
        }
        const roles =
            store?.state?.user?.user?.roles ||
            (await store.dispatch("user/getInfo")).roles;

        if (
            to.meta.roles.length === 0 ||
            to.meta.roles.some((role) => roles && roles.includes(role))
        ) {
            next();
        } else next("/404");
    } else {
        if (whiteList.indexOf(to.name) !== -1) {
            next();
        } else {
            next("/pages/login");
        }
    }
});

router.afterEach(() => {
    NProgress.done();
});
