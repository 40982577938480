import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/sass/overrides.sass";
import i18n from "@/i18n";
const theme = {
  primary: "#F3C309",
  secondary: "#4B53BC",
  accent: "#A1989C",
  info: "#00CAE3"
};
Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
});
