export default [
    {
        name: "Login",
        path: "login",
        component: () => import("@/views/pages/Login"),
    },
    {
        name: "Supervisor Login",
        path: "/giamthi",
        component: () => import("@/views/pages/SupervisorLogin"),
    },
    {
        name: "Assistant Login",
        path: "/troly",
        component: () => import("@/views/pages/AssistantLogin"),
    },
    {
        path: "/403",
        name: "Forbidden",
        component: () => import("@/views/pages/403"),
    },
    {
        name: "404",
        path: "/404",
        component: () => import("@/views/pages/404"),
    },
];
